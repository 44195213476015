<template>
  <div class="category-search-meta">
    <div class="amount-container ">
      <div v-if="itemsAmount > 0">
        <div class="amount-text">
          <span class="total-amount">{{totalItems}}</span><span> tuotetta</span>
        </div>
      </div>
    </div>
    <div v-if="siblingFiltersSliced.length > 0" class="category-quick-items">
      <div
        v-for="(item, key) in siblingFiltersSliced"
        :key="key"
        @click="categorySelector(item, item.groupTitle, item.siblings);"
        class="category-quick-items__button leading-none button-round mb-3 mr-6 inline-flex flex-no-shrink px-6"
        :class="_.find(selectedCategories, {id: item.id}) ? 'button button--red' : 'button'"
      >
        <span>{{ item.title }}</span>
        <svg v-if="_.find(selectedCategories, {id: item.id})" width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.875 1L9.875 9M1.875 9L9.875 1L1.875 9Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div
        v-if="siblingFilters.length > 2"
        class="show-more-filters category-quick-items__button leading-none button-round mb-3 mr-6 inline-flex flex-no-shrink px-6 button"
        @click="openFilters()"
      >+ Näytä lisää
      </div>
    </div>
    <search-sorting
      :sort-by-text="sortByText"
      :sorting.sync="searchSorting"
      :options="sortingOptions"
    ></search-sorting>
  </div>
</template>

<script>
import Vue from 'vue';
import SearchSorting from './SearchSorting'
import {translation} from '../../js/translation'

export default {
  name: 'CategorySearchMeta',
  props: {
    totalItems: {
      type: Number,
      default: 0
    },
    itemOffset: {
      type: Number,
      default: 0
    },
    elementsPerPage: {
      type: Number,
      default: 0
    },
    selectedCategories: {
      type: Array,
      default: []
    },
    sortingOptions: {
      type: Array,
      default: () => [
        {
          title: 'Newest first',
          value: 'postdate desc'
        },
        {
          title: 'Oldest first',
          value: 'postdate asc'
        },
        {
          title: 'Title A-Z',
          value: 'title asc'
        },
        {
          title: 'Title Z-A',
          value: 'title desc'
        }
      ]
    },
    sortByText: {
      type: String,
      default: ''
    },
    filtersOpen: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      searchSorting: '',
      siblingFilters: [],
    }
  },
  computed: {
    _() {
      return _
    },
    itemsAmount: function() {
      const itemsLeft = this.totalItems - this.itemOffset > this.elementsPerPage ? this.itemOffset + this.elementsPerPage : this.totalItems;

      return itemsLeft;
    },
    siblingFiltersSliced: function() {
      const slicedSiblingFilters = this.siblingFilters.slice(0, 3) || [];

      return slicedSiblingFilters;
    }
  },
  filters: {
    t(val) {
      const locale = document.getElementById('productSearchApp').dataset.locale || 'fi-FI';
      const trans = translation[locale];
      return trans[val] || val;
    },
  },
  mounted: function() {
    this.showSiblingFilters();
  },
  watch: {
    searchSorting: function(val) {
      this.$emit('update:searchSorting', val)
    },
    selectedCategories: function() {
      this.showSiblingFilters();
    }
  },
  methods: {
    openFilters: function() {
      this.$emit('update:filters-open', true)
    },
    showSiblingFilters: function() {
      const self = this;
      let filters = [];
      if (self.selectedCategories.length) {
        const categoryWithSiblings = _.find(self.selectedCategories, item => {
          return item.siblings;
        });
        if (categoryWithSiblings) {
          filters = _.map(categoryWithSiblings.siblings, item => {
            let catObj = {}
            catObj.title = _.get(item, 'title')
            catObj.slug = _.get(item, 'slug')
            catObj.id = _.get(item, 'id')
            catObj.level = _.get(item, 'level')
            if (item.siblings) {
              catObj.siblings = item.siblings;
            }
            catObj.groupTitle = item.groupTitle;

            return catObj;
          });
        }
      }

      self.siblingFilters = filters;
    },
    categorySelector: function(cat, groupCat, childrenParam) {
      let newCats = []
      let siblings = childrenParam;
      this.selectedCategories.forEach(element => {
      if (element.level != 1) {
        siblings = element.siblings;
        newCats.push(element)
      }
      if (element.level == 1) {
        siblings = element.siblings;
      }
      })
      if (!_.get(cat, 'id')) {
        newCats = _.reject(newCats, {
          groupTitle: this.categoryFilters[0].groupTitle,
        })
      } else {
        if (_.find(this.selectedCategories, {id: cat.id})) {
          _.remove(newCats, {id: cat.id})
        } else {
          let catObj = {}
          if (!cat) {
          } else {
            catObj.title = _.get(cat, 'title')
            catObj.slug = _.get(cat, 'slug')
            catObj.id = _.get(cat, 'id')
            catObj.level = _.get(cat, 'level')
            if (childrenParam) {
              catObj.siblings = childrenParam;
            } else if (siblings) {
              catObj.siblings = siblings;
            }
            if (cat.siblings)
            catObj.groupTitle = groupCat;
            newCats.push(catObj)
          }
        }
      }
      this.$emit('update:selected-categories', newCats)
      
    },
  },
  components: {
    SearchSorting
  },
};
</script>
